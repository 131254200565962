import React from "react";
import { mapping } from "../style/mapping";
import { Tab } from "./Tab";
import { DarkenColor } from "./util";
import { IconSVG } from "./Icon";

// this component builds up the risk matrix
// the props it needs:
// MatrixSetting:  a 2d array of risk rating from 1 (lowest) to 3 (highest)
// riskRating: a list of object [{riskNumber, riskLikihood, impact}]

// ===================================== setting  ======================================

const MatrixSetting = [
    ["2", "3", "3", "3", "3"],
    ["1", "2", "2", "3", "3"],
    ["1", "1", "2", "3", "3"],
    ["1", "1", "2", "2", "3"],
    ["1", "1", "2", "2", "2"]
];

const riskRating = [
    //{riskName:'R1. Test Risk1', riskNumber:'R01', Gross:{likelihood:'2', impact:'3'}, Residual:{likelihood:'2', impact:'3'}, Target:{likelihood:'1', impact:'3'}},
    //{riskName:'R2. Test Risk2', riskNumber:'R02', Gross:{likelihood:'2', impact:'3'}, Residual:{likelihood:'3', impact:'2'}, Target:{likelihood:'2', impact:'1'}},
    //{riskName:'R3. Test Risk3', riskNumber:'R03', Gross:{likelihood:'2', impact:'3'}, Residual:{likelihood:'4', impact:'1'}, Target:{likelihood:'3', impact:'4'}},
    {
        riskName: "R4. Test Risk4",
        riskNumber: "R03",
        Gross: { likelihood: "2", impact: "3" },
        Residual: { likelihood: "5", impact: "3" },
        Target: { likelihood: "1", impact: "5" }
    }
];

const riskNumberPerRow = 2;

const categoryList = ["Gross", "Residual", "Target"];

// ============================================ style ========================================
const RM_Background = {
    height: "628px",
    width: "460px",
    backgroundColor: mapping["ComponentBGColor"]
};

const RM_Title_Div = {
    display: "inline-flex",
    height: "68px",
    width: "420px"
};

const RM_Title_Font = {
    padding: "24px 62px 30px 18px"
};

const RM_Padding = {
    padding: "14px"
};

const RM_Title_Risk_Name = {
    paddingLeft: "19px",
    paddingTop: "11px",
    color: mapping["Color_Text_Primary"]
};

const RM_Vertical_Axis_Div = {
    height: mapping["RM_Block_Size"],
    width: mapping["RM_Block_Size"],
    margin: mapping["RM_Block_Margin"],
    display: "inline-flex",
    position: "absolute"
};

const RM_Vertical_Axis_Text = {
    width: "44px",
    display: "inline-flex",
    paddingRight: "8px",
    margin: "auto"
};

const RM_Vertical_Axis_Number = {
    width: "14px",
    display: "inline-flex",
    margin: "auto"
};

const RM_Horizontal_Axis = {
    height: "39px",
    width: mapping["RM_Block_Size"],
    margin: mapping["RM_Block_Margin"],
    position: "absolute"
};

const RM_Horizontal_Axis_Text = {
    height: "14px",
    width: mapping["RM_Block_Size"]
};

const RM_Horizontal_Axis_Number = {
    height: "24px",
    width: mapping["RM_Block_Size"],
    padding: "1px"
};

const RM_Horizontal_Axis_Label = {
    width: "27rem",
    paddingTop: "66px",
    paddingRight: "12px"
};

const RM_Category_Label_Div = {
    height: mapping["RM_Block_Size"],
    width: mapping["RM_Block_Size"],
    position: "absolute",
    display: "inline-flex"
};

const RM_Category_Label = {
    width: mapping["RM_Block_Size"],
    margin: "auto"
};

const RM_Block_Size = {
    height: mapping["RM_Block_Size"],
    width: mapping["RM_Block_Size"],
    margin: mapping["RM_Block_Margin"],
    borderRadius: mapping["RM_Block_Radius"],
    display: "inline-flex"
};

const RM_RiskNumber_Size = {
    height: mapping["RM_Risk_Number_Size"],
    width: mapping["RM_Risk_Number_Size"],
    margin: mapping["RM_RIsk_Number_Margin"],
    borderRadius: mapping["RM_Risk_Number_Radius"]
};

const RM_RiskNumber_bg = {
    backgroundColor: "white"
};

const reGroupMultiRiskRating = (category, riskRating) => {
    var reGroupResult = [
        [[], [], [], [], []],
        [[], [], [], [], []],
        [[], [], [], [], []],
        [[], [], [], [], []],
        [[], [], [], [], []]
    ];

    riskRating.map(rating => {
        // select only the active category
        rating.likelihood = parseInt(
            rating[category.toLowerCase()]["likelihood"]
        );
        rating.impact = parseInt(rating[category.toLowerCase()]["impact"]);

        // put riskNumber into 2d array
        var row = 5 - rating.likelihood;
        var col = rating.impact - 1;
        reGroupResult[row][col].push(rating.riskNumber);
    });
    return reGroupResult;
};

const reGroupSingleRiskRating = (riskRatingList, categoryList) => {
    var reGroupResult = [
        [[], [], [], [], []],
        [[], [], [], [], []],
        [[], [], [], [], []],
        [[], [], [], [], []],
        [[], [], [], [], []]
    ];

    var riskRating = riskRatingList[0];
    categoryList.map(cate => {
        var row = 5 - riskRating[cate]["likelihood"];
        var col = riskRating[cate]["impact"] - 1;
        reGroupResult[row][col].push(cate);
    });
    return reGroupResult;
};

const VerticalAxis = props => {
    var rating = props.MatrixSetting.length - props.rows;
    switch (rating) {
        case 5:
            return (
                <div style={RM_Vertical_Axis_Div}>
                    <div
                        style={Object.assign(
                            {},
                            RM_Vertical_Axis_Text,
                            mapping["paragraph/tiny/lightright"]
                        )}
                    >
                        <span style={{ width: "100%" }}>Very Likely</span>
                    </div>
                    <div
                        style={Object.assign(
                            {},
                            RM_Vertical_Axis_Number,
                            mapping["paragraph/default/lightright"]
                        )}
                    >
                        5
                    </div>
                </div>
            );
        case 4:
            return (
                <div style={RM_Vertical_Axis_Div}>
                    <div
                        style={Object.assign(
                            {},
                            RM_Vertical_Axis_Text,
                            mapping["paragraph/tiny/lightright"]
                        )}
                    >
                        <span style={{ width: "100%" }}>Likely</span>
                    </div>
                    <div
                        style={Object.assign(
                            {},
                            RM_Vertical_Axis_Number,
                            mapping["paragraph/default/lightright"]
                        )}
                    >
                        4
                    </div>
                </div>
            );
        case 3:
            return (
                <div style={RM_Vertical_Axis_Div}>
                    <div
                        style={Object.assign(
                            {},
                            RM_Vertical_Axis_Text,
                            mapping["paragraph/tiny/lightright"]
                        )}
                    >
                        <span style={{ width: "100%" }}>Moderate</span>
                    </div>
                    <div
                        style={Object.assign(
                            {},
                            RM_Vertical_Axis_Number,
                            mapping["paragraph/default/lightright"]
                        )}
                    >
                        3
                    </div>
                </div>
            );
        case 2:
            return (
                <div style={RM_Vertical_Axis_Div}>
                    <div
                        style={Object.assign(
                            {},
                            RM_Vertical_Axis_Text,
                            mapping["paragraph/tiny/lightright"]
                        )}
                    >
                        <span style={{ width: "100%" }}>Unlikely</span>
                    </div>
                    <div
                        style={Object.assign(
                            {},
                            RM_Vertical_Axis_Number,
                            mapping["paragraph/default/lightright"]
                        )}
                    >
                        2
                    </div>
                </div>
            );
        case 1:
            return (
                <div style={RM_Vertical_Axis_Div}>
                    <div
                        style={Object.assign(
                            {},
                            RM_Vertical_Axis_Text,
                            mapping["paragraph/tiny/lightright"]
                        )}
                    >
                        <span style={{ width: "100%" }}>Very Unlikely</span>
                    </div>
                    <div
                        style={Object.assign(
                            {},
                            RM_Vertical_Axis_Number,
                            mapping["paragraph/default/lightright"]
                        )}
                    >
                        1
                    </div>
                </div>
            );
        default:
            return <div />;
    }
};

const HorizontalAxis = props => {
    const blockSize = parseInt(mapping["RM_Block_Size"].split("p")[0]);
    const blockMargin = parseInt(mapping["RM_Block_Margin"].split("p")[0]);
    var padding = [
        blockSize + 2 * blockMargin,
        2 * (blockSize + 2 * blockMargin),
        3 * (blockSize + 2 * blockMargin),
        4 * (blockSize + 2 * blockMargin),
        5 * (blockSize + 2 * blockMargin)
    ];

    return (
        <div>
            <div>
                <div
                    style={Object.assign({}, RM_Horizontal_Axis, {
                        paddingLeft: padding[0] + "px"
                    })}
                >
                    <div
                        style={Object.assign(
                            {},
                            RM_Horizontal_Axis_Number,
                            mapping["paragraph/default/lightcenter"]
                        )}
                    >
                        1
                    </div>
                    <div
                        style={Object.assign(
                            {},
                            RM_Horizontal_Axis_Text,
                            mapping["paragraph/tiny/lightcenter"]
                        )}
                    >
                        <span style={{ width: "100%" }}>Very Low</span>
                    </div>
                </div>
                <div
                    style={Object.assign({}, RM_Horizontal_Axis, {
                        paddingLeft: padding[1] + "px"
                    })}
                >
                    <div
                        style={Object.assign(
                            {},
                            RM_Horizontal_Axis_Number,
                            mapping["paragraph/default/lightcenter"]
                        )}
                    >
                        2
                    </div>
                    <div
                        style={Object.assign(
                            {},
                            RM_Horizontal_Axis_Text,
                            mapping["paragraph/tiny/lightcenter"]
                        )}
                    >
                        <span style={{ width: "100%" }}>Low</span>
                    </div>
                </div>
                <div
                    style={Object.assign({}, RM_Horizontal_Axis, {
                        paddingLeft: padding[2] + "px"
                    })}
                >
                    <div
                        style={Object.assign(
                            {},
                            RM_Horizontal_Axis_Number,
                            mapping["paragraph/default/lightcenter"]
                        )}
                    >
                        3
                    </div>
                    <div
                        style={Object.assign(
                            {},
                            RM_Horizontal_Axis_Text,
                            mapping["paragraph/tiny/lightcenter"]
                        )}
                    >
                        <span style={{ width: "100%" }}>Moderate</span>
                    </div>
                </div>
                <div
                    style={Object.assign({}, RM_Horizontal_Axis, {
                        paddingLeft: padding[3] + "px"
                    })}
                >
                    <div
                        style={Object.assign(
                            {},
                            RM_Horizontal_Axis_Number,
                            mapping["paragraph/default/lightcenter"]
                        )}
                    >
                        4
                    </div>
                    <div
                        style={Object.assign(
                            {},
                            RM_Horizontal_Axis_Text,
                            mapping["paragraph/tiny/lightcenter"]
                        )}
                    >
                        <span style={{ width: "100%" }}>High</span>
                    </div>
                </div>
                <div
                    style={Object.assign({}, RM_Horizontal_Axis, {
                        paddingLeft: padding[4] + "px"
                    })}
                >
                    <div
                        style={Object.assign(
                            {},
                            RM_Horizontal_Axis_Number,
                            mapping["paragraph/default/lightcenter"]
                        )}
                    >
                        5
                    </div>
                    <div
                        style={Object.assign(
                            {},
                            RM_Horizontal_Axis_Text,
                            mapping["paragraph/tiny/lightcenter"]
                        )}
                    >
                        <span style={{ width: "100%" }}>Very High</span>
                    </div>
                </div>
            </div>
            <div
                style={Object.assign(
                    {},
                    RM_Horizontal_Axis_Label,
                    mapping["paragraph/small/lightright"]
                )}
            >
                Magnitude of Impact
            </div>
        </div>
    );
};

const RiskNumber = props => {
    var riskNumbers = props.riskRating;
    return riskNumbers.map((number, index) => {
        var top =
            Math.floor(index / riskNumberPerRow) *
            (1 / riskNumberPerRow) *
            mapping["RM_Block_Size"].split("p")[0];
        var left =
            (index % riskNumberPerRow) *
            (1 / riskNumberPerRow) *
            mapping["RM_Block_Size"].split("p")[0];
        const positionStyle = {
            position: "absolute",
            top: top + "px",
            left: left + "px"
        };
        return (
            <div
                style={Object.assign(
                    {},
                    RM_RiskNumber_Size,
                    RM_RiskNumber_bg,
                    positionStyle
                )}
            >
                <span>{number}</span>
            </div>
        );
    });
};

const Risk_Matrix_Component_Block = props => {
    const Color_Mapiing = {
        "1": mapping["Color_Platform_RiskLow"],
        "2": mapping["Color_Platform_RiskMed"],
        "3": mapping["Color_Platform_RiskHigh"]
    };

    var riskRating = [];

    // single risk and multiple need different template
    if (props.singleRisk) {
        riskRating = reGroupSingleRiskRating(
            props.riskRating,
            props.categoryList
        );
    } else {
        riskRating = reGroupMultiRiskRating(props.category, props.riskRating);
    }

    const MyMatrixSetting = props.MatrixSetting
        ? props.MatrixSetting
        : MatrixSetting;
    return (
        <div>
            {MyMatrixSetting.map((rows, likelihood) => {
                return (
                    <div style={{ display: "inline-flex" }}>
                        {/* Risk Matrix */}
                        {rows.map((risklevel, impact) => {
                            // when hover, focus on single risk
                            var blockColor = Color_Mapiing[risklevel];
                            var blockInactiveColor = DarkenColor(
                                Color_Mapiing[risklevel],
                                0.4
                            );
                            var backgroundColor =
                                riskRating[likelihood][impact] == ""
                                    ? blockInactiveColor
                                    : blockColor;
                            return (
                                <div
                                    style={Object.assign({}, RM_Block_Size, {
                                        backgroundColor: backgroundColor,
                                        position: "relative"
                                    })}
                                >
                                    <div style={RM_Category_Label_Div}>
                                        <div
                                            style={Object.assign(
                                                {},
                                                RM_Category_Label,
                                                mapping[
                                                    "paragraph/small/lightcenter"
                                                ]
                                            )}
                                        >
                                            {riskRating[likelihood][impact].map(
                                                name => {
                                                    return (
                                                        name
                                                            .charAt(0)
                                                            .toUpperCase() +
                                                        name.slice(1) +
                                                        "\n"
                                                    );
                                                }
                                            )}
                                        </div>
                                    </div>
                                </div>
                            );
                        })}
                    </div>
                );
            })}
        </div>
    );
};

const Risk_Matrix_Component = props => {
    const Color_Mapiing = {
        "1": mapping["Color_Platform_RiskLow"],
        "2": mapping["Color_Platform_RiskMed"],
        "3": mapping["Color_Platform_RiskHigh"]
    };

    var riskRating = [];

    // single risk and multiple need different template
    if (props.singleRisk) {
        riskRating = reGroupSingleRiskRating(
            props.riskRating,
            props.categoryList
        );
    } else {
        riskRating = reGroupMultiRiskRating(props.category, props.riskRating);
    }

    const MyMatrixSetting = props.MatrixSetting
        ? props.MatrixSetting
        : MatrixSetting;
    return (
        <div>
            <div style={mapping["paragraph/small/lightleft"]}>
                {" "}
                Likelihood of Occurrence{" "}
            </div>
            {MyMatrixSetting.map((rows, likelihood) => {
                return (
                    <div style={{ display: "inline-flex" }}>
                        {/* ventricle axis element */}
                        <VerticalAxis
                            MatrixSetting={MyMatrixSetting}
                            rows={likelihood}
                        />
                        <div
                            style={Object.assign({}, RM_Block_Size, {
                                backgroundColor:
                                    mapping["Color_Extra_Transparent"]
                            })}
                        />

                        {/* Risk Matrix */}
                        {rows.map((risklevel, impact) => {
                            // when hover, focus on single risk
                            if (props.singleRisk) {
                                var blockColor = Color_Mapiing[risklevel];
                                var blockInactiveColor = DarkenColor(
                                    Color_Mapiing[risklevel],
                                    0.4
                                );
                                var backgroundColor =
                                    riskRating[likelihood][impact] == ""
                                        ? blockInactiveColor
                                        : blockColor;

                                return (
                                    <div
                                        style={Object.assign(
                                            {},
                                            RM_Block_Size,
                                            {
                                                backgroundColor: backgroundColor,
                                                position: "relative"
                                            }
                                        )}
                                    >
                                        <div style={RM_Category_Label_Div}>
                                            <div
                                                style={Object.assign(
                                                    {},
                                                    RM_Category_Label,
                                                    mapping[
                                                        "paragraph/small/lightcenter"
                                                    ]
                                                )}
                                            >
                                                {riskRating[likelihood][
                                                    impact
                                                ].map(name => {
                                                    return (
                                                        name
                                                            .charAt(0)
                                                            .toUpperCase() +
                                                        name.slice(1) +
                                                        "\n"
                                                    );
                                                })}
                                            </div>
                                        </div>
                                    </div>
                                );
                            } else {
                                // when not hover, display all risks
                                return (
                                    <div
                                        style={Object.assign(
                                            {},
                                            RM_Block_Size,
                                            {
                                                backgroundColor:
                                                    Color_Mapiing[risklevel],
                                                position: "relative"
                                            }
                                        )}
                                    >
                                        <RiskNumber
                                            riskRating={
                                                riskRating[likelihood][impact]
                                            }
                                        />
                                    </div>
                                );
                            }
                        })}
                    </div>
                );
            })}
            <HorizontalAxis />
        </div>
    );
};

class RiskMatrix extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            matrixSetting: this.props.matrixSetting
                ? this.props.matrixSetting
                : MatrixSetting,
            riskRating: this.props.riskRating,
            singleRisk: this.props.singleRisk,
            categoryList: this.props.categoryList,
            riskRatingCategory: this.props.categoryList[0]
        };
    }

    static getDerivedStateFromProps(nextProps, prevState) {
        return {
            riskRating: nextProps.riskRating,
            singleRisk: nextProps.singleRisk,
            categoryList: nextProps.categoryList
        };
    }

    render() {
        const OnTabChange = category => {
            this.setState({ riskRatingCategory: category });
        };

        // console.log(this.state.riskRating);
        return (
            <div style={RM_Background}>
                <div style={RM_Title_Div}>
                    <div
                        style={Object.assign(
                            {},
                            RM_Title_Font,
                            mapping["heading/h5/lightleft"]
                        )}
                    >
                        {" "}
                        Risk Matrix{" "}
                    </div>
                </div>
                {/* eslint-disable-next-line jsx-a11y/no-static-element-interactions */}
                <div
                    className={"div_hover"}
                    style={{ display: "inline-flex" }}
                    onClick={this.props.handleInformationClick}
                >
                    <IconSVG
                        name={"info"}
                        color={mapping["Color_Basic_Link"]}
                    />
                </div>
                <div style={{ height: "38px" }}>
                    {this.state.singleRisk ? (
                        <div
                            style={Object.assign(
                                {},
                                mapping["heading/h5/lightleft"],
                                RM_Title_Risk_Name
                            )}
                        >
                            {this.state.riskRating[0].name}
                        </div>
                    ) : (
                        <Tab
                            height={"36px"}
                            width={"326px"}
                            labels={categoryList}
                            OnTabChange={OnTabChange}
                        />
                    )}
                </div>
                <div style={RM_Padding}>
                    <Risk_Matrix_Component
                        MatrixSetting={this.state.matrixSetting}
                        singleRisk={this.state.singleRisk}
                        riskRating={this.state.riskRating}
                        category={this.state.riskRatingCategory}
                        categoryList={this.state.categoryList}
                    />
                </div>
            </div>
        );
    }
}

function getRiskRating(likelihood, impact) {
    return parseInt(
        MatrixSetting[MatrixSetting.length - likelihood][impact - 1]
    );
}

export {
    RiskMatrix,
    Risk_Matrix_Component,
    Risk_Matrix_Component_Block,
    getRiskRating
};
