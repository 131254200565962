import * as React from "react";
import { mapping } from "../style/mapping";
import {
    DatePicker,
    MultiViewCalendar,
    ToggleButton,
    DateTimePicker
} from "@progress/kendo-react-dateinputs";
import { Popup } from "@progress/kendo-react-popup";
import { IconSVG } from "./Icon";

class CustomDatePicker extends React.Component {
    render() {
        return (
            <DatePicker
                index={this.props.index}
                //dateInput={CustomDateInput}
                calendar={CustomCalendar}
                popup={CustomPopup}
                toggleButton={CustomToggleButton}
                defaultValue={new Date(this.props.value)}
                width={this.props.width ? this.props.width : 163}
                height={31}
                onChange={this.handleDateChange}
                disabled={this.props.disabled}
            />
        );
    }
    handleDateChange = event => {
        console.log(event.value, this.props.index);
        this.props.CustomOnChange(event.value, this.props.index);
    };
}
class CustomDateTimePicker extends React.Component {
    render() {
        return (
            <DateTimePicker
                index={this.props.index}
                //dateInput={CustomDateInput}
                calendar={CustomCalendar}
                popup={CustomPopup}
                toggleButton={CustomToggleButton}
                defaultValue={new Date(this.props.value)}
                width={this.props.width ? this.props.width : 163}
                height={31}
                onChange={this.handleDateChange}
                disabled={this.props.disabled}
                show={true}
            />
        );
    }
    handleDateChange = event => {
        console.log(event.value, this.props.index);
        this.props.CustomOnChange(event.value, this.props.index);
    };
}
class CustomDateInput extends React.Component {
    inputStyle = {
        width: 200,
        borderColor: "transparent",
        backgroundColor: "red"
    };
    handleChange = syntheticEvent => {
        const date = {
            day: this.props.value.getDate(),
            month: this.props.value.getMonth(),
            year: this.props.value.getFullYear()
        };

        date[event.target.getAttribute("data-section")] = Number(
            event.target.value
        );

        const value = new Date(date.year, date.month, date.day);

        this.props.onChange({
            value,
            syntheticEvent,
            target: this
        });
    };
    render() {
        return (
            <input
                style={this.inputStyle}
                value={this.props.value}
                onChange={this.handleChange}
            />
        );
    }
}
class CustomCalendar extends React.Component {
    render() {
        return (
            <MultiViewCalendar
                style={{ width: "530px" }}
                views={1}
                value={this.props.value}
                onChange={this.props.onChange}
            />
        );
    }
}
class CustomPopup extends React.Component {
    render() {
        return (
            <Popup
                style={{
                    padding: 0,
                    marginTop: "2.5rem",
                    transform: "scale(0.7)",
                    transformOrigin: "top center"
                }}
                {...this.props}
                anchorAlign={{
                    horizontal: "center",
                    vertical: "top"
                }}
                popupAlign={{
                    horizontal: "center",
                    vertical: "top"
                }}
            />
        );
    }
}

class CustomToggleButton extends React.Component {
    render() {
        return (
            <ToggleButton
                {...this.props}
                style={{
                    backgroundColor: mapping["Color_Form_00_Field_Solid"],
                    border: "none"
                }}
            >
                <IconSVG name={"calendar"} color={"white"} size={0.6} />
            </ToggleButton>
        );
    }
}
export { CustomDatePicker, CustomDateTimePicker };
