import React from "react";
import { Checkbox } from "@progress/kendo-react-inputs";

class CustomizedCheckBox extends React.Component {

    render() {
        console.log(this.props.label+" : "+this.props.value)
        return (
            <div style={{ display: "flex", ...this.props.checkBoxDivStyle }}>
                <div
                    style={{
                        margin: "auto",
                        ...this.props.checkBoxWrapperStyle
                    }}
                >
                    <Checkbox
                        style={{
                            backgroundColor: this.props.value
                                ? "#00BEFF"
                                : "rgba(255,255,255,0.11)",
                            border: "1px solid #00BEFF",
                            borderRadius: "4px",
                            ...this.props.checkBoxStyle
                        }}
                        label={this.props.label}
                        checked={this.props.value}
                        onChange={this.props.onCheckChange}
                        disabled={this.props.disabled}
                        labelPlacement={
                            this.props.labelPlacement
                                ? this.props.labelPlacement
                                : "after"
                        }
                    />
                </div>
            </div>
        );
    }
}

export { CustomizedCheckBox };
